import success from "../images/success.gif";
import message from "../images/message.gif";
import job from "../images/job.gif";

import { useNavigate } from "react-router";
import { t } from "i18next";

function Success(props) {
  const history = useNavigate();

  return (
    <div className="foodModal successModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        className="modal-content logoutModal"
        style={{
          height: "auto",
          width: "90vw ",
          maxWidth: "570px",
          minWidth: "337px",
        }}
      >
        <div>
          <div className="modalBody">
            {props.type == "success" ? (
              <div
                className="successImg"
                style={{ backgroundImage: `url(${success})` }}
              ></div>
            ) : props.type == "job" ? (
              <div className="successJob">
                <div
                  className="successImg "
                  style={{ backgroundImage: `url(${job})` }}
                ></div>
              </div>
            ) : (
              <div
                className="successImg"
                style={{ backgroundImage: `url(${message})` }}
              ></div>
            )}
            <div
              className="paddingText"
              style={props.type == "job" ? { border: "none" } : null}
            >
              <p id={props.type == "job" ? "successTitle2" : "successTitle"}>
                {props.type == "job"
                  ? t("success.job")
                  : props.type == "success"
                  ? t("success.quote")
                  : t("success.msg")}
              </p>
              <p id="successTitle3">
                {" "}
                {props.type == "job"
                  ? t("success.job2")
                  : props.type == "success"
                  ? t("success.msg4")
                  : props.type == "feedback"
                  ? t("success.msg3")
                  : t("success.msg2")}
              </p>
            </div>
            <div className="paddingText">
              <button
                onClick={() => {
                  props.setModal(false);
                  history("/");
                }}
                id="backHome"
              >
                {t("success.back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
