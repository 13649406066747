import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../style/whatsAppIcon.css";

const WhatsAppIcon = () => {
  return (
    <a
      href="https://api.whatsapp.com/send/?phone=%2B201000759105"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <FaWhatsapp size={28} className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppIcon;
