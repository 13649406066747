import logo from "../images/logo.svg";
import { BiChevronDown } from "react-icons/bi";
import logoWhite from "../images/logoWhite.svg";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import "../style/home.css";
function Footer() {
  const { t, i18n } = useTranslation();
  var dt = new Date();
  const year = dt.getFullYear();

  const currentLanguage = i18n.language;

  return (
    <div>
      {" "}

      <div className={`footer ${currentLanguage === 'ar' ? 'arabicStyleProductsFooter' : ''}`}>
        <div className="container-fluid" style={{ padding: "3vw 6vw" }}>
          <div className="row">
            <div className="col-sm-4">
              <div
                className="logo logoW"
                style={{ backgroundImage: `url(${logoWhite})` }}
              ></div>
              <p className="footerTxt">{t("footer.desribe")}</p>
            </div>
            <div className="col-sm-3 col-4 siteMap">
              <p id="sitesTitle">{t("footer.site")}</p>
              <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
                {" "}
                <p>{t("navbar.home")}</p>
              </NavLink>
              <NavLink to="/about-us">
                {" "}
                <p>{t("navbar.about")}</p>
              </NavLink>
              <NavLink to="/process">
                {" "}
                <p>{t("navbar.process")}</p>
              </NavLink>
              <HashLink className="anchor" to="/#services">
                {" "}
                <p>{t("navbar.services")}</p>
              </HashLink>
              {/* <NavLink to="/products">
                {" "}
                <p>{t("navbar.products")}</p>
              </NavLink> */}

              {/* <p>{t("navbar.clients")}</p>
              <p>{t("navbar.projects")}</p>
              <p>{t("navbar.careers")}</p> */}
              {/* <HashLink className="anchor" to="/#clients">
                <p>{t("navbar.clients")}</p>
              </HashLink>
              <NavLink to="/projects">
                <p>{t("navbar.projects")}</p>
              </NavLink>
              <NavLink to="/career">
                <a href="#careers">
                  <p>{t("navbar.careers")}</p>
                </a>
              </NavLink> */}

              {/* <NavLink to="/team"><p>{t("navbar.team")}</p></NavLink> */}
            </div>
            <div className=" col-sm-5 col-8 siteMap contacts">
              <p id="sitesTitle">{t("footer.company")}</p>
              <p style={{ color: "white" }}>
                <IoIosInformationCircleOutline size={25} /> {t("footer.comp1")}
              </p>
              <p style={{ color: "white" }}>
                <IoLocationOutline size={25} /> {t("footer.comp2")}{" "}
              </p>
              <p style={{ color: "white" }}>
                <IoLocationOutline size={25} /> {t("footer.comp3")}{" "}
              </p> 
              {/* <a href={`mailto:ab.Yakout@bdaiat.com`}>
                <p style={{ cursor: "pointer", textDecoration: "underline" }}>
                  <AiOutlineMail size={25} />
                  ab.Yakout@bdaiat.com
                </p>
              </a> */}
              <a href={`mailto:sales@bdaiat.com`}>
                <p style={{ cursor: "pointer", textDecoration: "underline" }}>
                  <AiOutlineMail size={25} />
                  sales@bdaiat.com
                </p>
              </a>
              <a href={`tel:+201000759105`}>
                <p
                  className={i18n.language == "ar" ? "arNum" : null}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <BsTelephone size={25} />
                  (+20) 100 075 9105
                </p>
              </a>
              <a href={`tel:+966534638774`}>
                <p
                  className={i18n.language == "ar" ? "arNum" : null}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <BsTelephone size={25} />
                  (+966) 53 463 8774
                </p>
              </a>{" "}
              
              {" "}
            </div>
          </div>
        </div>
        <div className="cr">
          <p>
            {t("footer.cr")}

            <span>&nbsp;{year} ©</span>
          </p>
          <div className="socialLinks">
            <a id="footerTwitt" href="https://twitter.com/BdaiatTech" target="_blank">
              <AiOutlineTwitter size={35} />
            </a>
            <a href="https://www.linkedin.com/company/bdaiat" target="_blank">
              <BsLinkedin size={25} />
            </a>
            <a href="https://www.facebook.com/bdaiat/" target="_blank">
              <AiFillFacebook size={35} />
            </a>
            <a href="https://www.instagram.com/bdaiat/" target="_blank">
              <BsInstagram size={25} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
