import Footer from "../components/Footer"
import "../style/products.css";
import Navbar from "../components/Navbar";

import MessengerCustomerChat from 'react-messenger-customer-chat';
import { useTranslation } from "react-i18next";
import { useState,useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {RxDoubleArrowRight} from "react-icons/rx";
import { Button, Modal } from 'react-bootstrap';

function Products() {

    const {t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    return(

        <div className="products-container" style={{direction : currentLanguage === 'ar' ? 'ltr': 'rtl'}}>

            <div style={{ position: "relative" }}>
                <div className="BdaitBluLogo"></div>
            </div>

            <div className="container-fluid besalla">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaImgsCol">
                        <div className={`bsImgOne ${currentLanguage === 'ar' ? 'arabicStyleBesallaImgsOne' : ''}`}>

                        </div>
                        <div className={`bsImgTwo ${currentLanguage === 'ar' ? 'arabicStyleBesallaImgsTwo' : ''}`}>

                        </div>
                    </div>
                    <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaTextCol ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextCol' : ''}`}>
                        <div className="besallaLogo" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}>

                        </div>
                        <div className="besallaText">
                            <h1 className={`${currentLanguage === 'ar' ? 'arabicStyleBesallaTextH1' : ''}`}>{t("products.title2")}</h1>
                            <p>{t("products.title")}</p>
                            <p className="besallaTextP2">{t("products.title3")}</p>

                            <Button variant="primary" onClick={handleShow}>
                                {t("products.btn")}
                            </Button>

                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <iframe 
                                    width="100%" 
                                    height="450" 
                                    src="https://crm.bdaiat.com/lead-web-form" 
                                    frameBorder="0" 
                                    allowFullScreen
                                    ></iframe>
                                </Modal.Body>
                            </Modal>
                            
                            {/* <div>
                                <button onClick={openModal}>{t("products.btn")}</button>
                                {isModalOpen && (
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button onClick={closeModal} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <iframe 
                                            width="100%" 
                                            height="450" 
                                            src="https://crm.bdaiat.com/lead-web-form" 
                                            frameBorder="0" 
                                            allowFullScreen
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div> */}

                            {/* <button>{t("products.btn")}</button> */}
                            {/* <input className={`${currentLanguage === 'ar' ? 'arabicStyleBesallaInput' : ''}`} type="email" placeholder={t("products.placeHolder")}/> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid aboutBesalla">
                <div className="row">
                    <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 besallaTextCol ${currentLanguage === 'ar' ? 'arabicStyleAboutBesallaTextCol' : ''}`}>
                        <div className="besallaLogo" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}>

                        </div>
                        <div className="besallaText">
                            <h1 style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>{t("products.title4")}</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol1 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol1' : ''}`}>
                        <div>
                            <div className="bsAboutImg1" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader1"><h1>{t("products.about2")}</h1></div>
                            <div className="bsAboutP1"><p>{t("products.text2")}</p></div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol2 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol2' : ''}`}>
                        <div>
                            <div className="bsAboutImg2" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader2"><h1>{t("products.about1")}</h1></div>
                            <div className="bsAboutP2"><p>{t("products.text1")}</p></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol3 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol3' : ''}`}>
                        <div>
                            <div className="bsAboutImg3" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader3"><h1>{t("products.about4")}</h1></div>
                            <div className="bsAboutP3"><p>{t("products.text4")}</p></div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol4 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol4' : ''}`}>
                        <div>
                            <div className="bsAboutImg4" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader4"><h1>{t("products.about3")}</h1></div>
                            <div className="bsAboutP4"><p>{t("products.text3")}</p></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutColRes ${currentLanguage === 'ar' ? 'arabicStyleAboutColRes' : ''}`}>
                        <div className="aboutCol5">
                            <div className="bsAboutImg5" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader5"><h1>{t("products.about6")}</h1></div>
                            <div className="bsAboutP5"><p>{t("products.text6")}</p></div>
                            <div className="bsAboutP7">

                            <p>
                                <Button variant="primary" onClick={handleShow} className={`${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7Btn' : ''}`}>
                                    {t("products.btn2")}
                                </Button>
                                {t("products.text7")}
                            </p>

                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <iframe 
                                    width="100%" 
                                    height="450" 
                                    src="https://crm.bdaiat.com/lead-web-form" 
                                    frameBorder="0" 
                                    allowFullScreen
                                    ></iframe>
                                </Modal.Body>
                            </Modal>
                                {/* <p><button className={`${currentLanguage === 'ar' ? 'arabicStyleBsAboutP7Btn' : ''}`}>{t("products.btn2")}</button>{t("products.text7")}</p> */}
                            </div>
                        </div>
                    </div>

                    <div className={`col-lg-6 col-md-12 col-sm-12 col-xs-12 aboutCol6 ${currentLanguage === 'ar' ? 'arabicStyleAboutCol6' : ''}`}>
                        <div>
                            <div className="bsAboutImg6" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                            <div className="bsAboutHeader6"><h1>{t("products.about5")}</h1></div>
                            <div className="bsAboutP6"><p>{t("products.text5")}</p></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid besallaEnd">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaImgsCol">
                        <div className="bsImgTwo">

                        </div>
                    </div>
                    <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 besallaTextCol ${currentLanguage === 'ar' ? 'arabicStyleBesallaTextCol' : ''}`}>
                        <div className="besallaText">
                            <h1  style={{textAlign : currentLanguage === 'ar' ? 'right': 'left'}}>{t("products.about7")}</h1>
                            <div className={`besallaLogo ${currentLanguage === 'ar' ? 'arabicStyleEndBesallaLogo' : ''}`}></div>
                            <a href="https://api.whatsapp.com/send?phone=01097101006" target="_blank">
                                <div className={`WhatsappYellowLogo ${currentLanguage === 'ar' ? 'arabicStyleBesallaWhatsLogo' : ''}`}></div>
                            </a>
                            {/* <div className={`WhatsappYellowLogo ${currentLanguage === 'ar' ? 'arabicStyleBesallaWhatsLogo' : ''}`}></div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>

    )
}

export default Products