import './App.scss';

import Home from './screens/Home';
import { BrowserRouter,Routes,Route,useLocation } from 'react-router-dom';
import AboutUs from './screens/AboutUs';
import Process from './screens/Process';
import Team from './screens/Team';
import Services from './screens/Services';
import Products from './screens/Products';
import Kites from './screens/Kites';
import JobDescription from './screens/JobDescription';
import Projects from './screens/Projects';
import Blogs from './screens/Blogs';
import BlogDetails from './screens/BlogDetails';
import Career from './screens/Career';
import ProjectDetails from './screens/ProjectDetails';
import Quotation from './screens/Quotation';
import Team2 from './screens/Team2';
import ServiceDetails from './screens/ServiceDetails';
import Feedback from './screens/Feedback';
import { useState } from 'react';
import Navbar from './components/Navbar';
import { useTranslation } from "react-i18next";
import { Outlet } from 'react-router';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import WhatsAppIcon from './screens/WhatsAppIcon';

ReactGA.initialize('UA-126014822-1');

function App() {
  const [mode, setMode] = useState(
    localStorage.getItem("mode") ? localStorage.getItem("mode") : "darkMode"
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location]);

  return (
    <div className={`App ${mode}`}>
      <div className={i18n.language == "ar" ? "homeAr" : null}>
        <WhatsAppIcon />

        <BrowserRouter>
          <Routes>
            <Route
              element={
                <>
                  <Outlet />
                </>
              }
            >
              <Route
                element={
                  <>
                    <Navbar setMode={setMode} />

                    <Outlet />
                  </>
                }
              >
                <Route exact path="/" element={<Home setMode={setMode} />} />
                <Route
                  exact
                  path="/about-us"
                  element={<AboutUs setMode={setMode} />}
                />
                <Route
                  exact
                  path="/process"
                  element={<Process setMode={setMode} />}
                />
                <Route
                  exact
                  path="/team"
                  element={<Team2 setMode={setMode} />}
                />
                <Route
                  exact
                  path="/services"
                  element={<Services setMode={setMode} />}
                />
                <Route
                  exact
                  path="/besalla"
                  element={<Products setMode={setMode} />}
                />
                <Route
                  exact
                  path="/kites"
                  element={<Kites setMode={setMode} />}
                />
                <Route
                  exact
                  path="/service"
                  element={<ServiceDetails setMode={setMode} />}
                />

                <Route
                  exact
                  path="/job-description/:id"
                  element={<JobDescription setMode={setMode} />}
                />
                <Route
                  exact
                  path="/projects"
                  element={<Projects setMode={setMode} />}
                />
                <Route
                  exact
                  path="/blogs"
                  element={<Blogs setMode={setMode} />}
                />
                <Route
                  exact
                  path="/projects/:id"
                  element={<ProjectDetails setMode={setMode} />}
                />
                <Route
                  exact
                  path="/blogs/:id"
                  element={<BlogDetails setMode={setMode} />}
                />

                <Route
                  exact
                  path="/career"
                  element={<Career setMode={setMode} />}
                />
                <Route
                  exact
                  path="/quotation"
                  element={<Quotation setMode={setMode} />}
                />

                {/* Quotation Route with Language Parameter */}
                <Route
                  path="/quotation/:lang"
                  element={<Quotation setMode={setMode} />}
                />
              </Route>
              <Route exact path="/feedback" element={<Feedback />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
